<template>
  <div>
     
        <div class="item">
            <div class="property_item heading_space">
                <div class="image">
                    <router-link v-if="$i18n.locale == 'en'" :to="'/properties/'+item.slug">
                        <img
                                v-lazy="(item.image) ? item.image.url : ''"
                                :src="(item.image) ? item.image.url : ''"
                                :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt : ''"
                                :title="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title : ''"
                                class="img-responsive">
                    </router-link>
                    <router-link v-if="$i18n.locale == 'ar'" :to="'/properties/'+item.slug+'?lang=ar'">
                        <img
                                v-lazy="(item.image) ? item.image.url : ''"
                                :src="(item.image) ? item.image.url : ''"
                                :alt="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_alt : item.image.en.image_alt : ''"
                                :title="(item.image) ?($i18n.locale == 'ar') ? item.image.ar.image_title : item.image.en.image_title : ''"
                                class="img-responsive">
                    </router-link>
                    <div class="feature">
                        <span class="tag-2">For Rent</span>
                    </div>
                    <div class="price clearfix">
                        <span class="tag pull-right">{{ item.price }}</span>
                    </div>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
export default {
    name: 'BestCardCard',
    props: ['item'],
    data(){
        return {
            //
        }
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
